<template>
    <div class="appform">
        <div class="content">
            <div style="display: flex;width: 100%;align-items: flex-start;">
                <div style="display: flex;flex: 1;">
                    <img src="../../assets/image/logo.png" style="width: 240px;height: auto; margin-right: 32px" />
                </div>
                <div style="text-align: right;">
                    <p style="color: #FF8E3B;font-size: 28px;font-family: GravityBold">Student Registration
                        Form</p>
                    <p style="margin-top: 10px;color: #FF8E3B;font-size: 18px;font-family: GravityBold"> {{
                        account_nameEN }}</p>
                </div>
            </div>

            <!-- <div class="title1">{{ project_name }}</div> -->
            <div style="margin: 15px 0px;font-size: 16px;line-height: 24px;">Congratulations on being nominated for the
                Extracurricular Activity Excellence Awards (EAE Awards).
                The EAE Awards is a global accreditation platform that recognizes the outstanding achievements of
                pre-collegiate students in extracurricular activities. To join, you need to register and activate your
                subscription.
                Please fill out this form to complete your registration. For more information, please visit:
                https://www.eae.online</div>
            <el-form :model="form" :rules="formRules" ref="form">
                <div class="row">
                    <div class="item">
                        <el-form-item label="Given Name (Native Language)" prop="givenName">
                            <div class="input">
                                <el-input v-model="form.givenName" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Last Name (Native Language)" prop="lastName">
                            <div class="input">
                                <el-input v-model="form.lastName" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Given Name (EN)" prop="givenName_pinyin">
                            <div class="input">
                                <el-input v-model="form.givenName_pinyin" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Last Name (EN)" prop="lastName_pinyin">
                            <div class="input">
                                <el-input v-model="form.lastName_pinyin" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Email" prop="email">
                            <div class="input">
                                <el-input v-model="form.email" placeholder="Please input"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="High School Graduation" prop="graduation">
                            <div class="input">
                                <el-select v-model="form.graduation" style="width: 400px;" placeholder="Please select"
                                    :popper-append-to-body="false" popper-class="customSelect">
                                    <el-option v-for="(year, index) in years" :value="year" :key="index"
                                        :label="year"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" style="align-items: flex-start">
                    <div class="item">
                        <el-form-item label="Guardian's Email" prop="student_guardian_first_email">
                            <div>
                                <div class="input">
                                    <el-input v-model="form.student_guardian_first_email"
                                        placeholder="Please input"></el-input>
                                </div>
                            </div>
                            <p style="color: #666;font-size: 12px;line-height: 16px;margin-top: 10px;">The guardian's
                                email only serves as a backup for communication if necessary.</p>

                        </el-form-item>
                    </div>
                    <div class="item">

                        <el-form-item label="Referrer's Email Address" v-if="register_type == 'share'" prop="">
                            <div>
                                <div class="input">
                                    <el-input v-model="form.referral_email" disabled
                                        placeholder="Please input"></el-input>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p style="line-height: 20px;"> I agree that EAE may use my provided information to set up a personal
                    account and <br> to email me about notifications, reminders, and extracurricular opportunities.</p>
            </div>
            <div class="submit">
                <div class="submitBtn" @click="submit">Submit</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    createApplicationByProjectAndAccount,
    getAccount,
    getProjectInfoByCode,
    getContactInfo
} from '../../api/eae'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            project_code: this.$route.query.project_code,
            account_id: this.$route.query.account_id,

            referral_email: '',
            isAgree: false,
            loading: false,
            years: [],
            form: {
                project_code: '',
                account_id: '',
                contact_id: '',
                lastName: '',
                givenName: '',
                lastName_pinyin: '',
                givenName_pinyin: '',
                graduation: '',
                email: '',
                student_guardian_first_email: ''
            },
            formRules: {
                givenName: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                lastName: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                account_nameEN: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                province: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                country: [
                    { required: true, message: "Please select", trigger: "change" },
                ],
                lastName_pinyin: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                givenName_pinyin: [
                    { required: true, message: "Please choose", trigger: "change" },
                ],
                graduation: [
                    { required: true, message: "Please choose", trigger: "change" },
                ],
                account_id: [
                    { required: true, message: "Please input", trigger: "change" },
                ],
                email: [{ required: true, message: "Please input", trigger: "change" }],
            },
            account_nameEN: '',
            project_name: '',
            register_type: 'individual',
        }
    },
    mounted() {

        this.form.project_code = this.project_code
        this.form.account_id = this.account_id
        this.form.referral_email = this.referral_email
        let current_year = new Date().getFullYear()
        let start_year = current_year
        for (let i = 1; i < 5; i++) {
            let year = start_year + i
            this.years.push(year)
        }
        if (this.$route.query.category_id) {
            this.form.category_id = this.$route.query.category_id
        }
        let contact_id = ""
        if (this.$route.query.contact_id) {
            contact_id = this.$route.query.contact_id
        }
        if (contact_id && contact_id != '') {//老师分享过来的
            this.register_type = 'share'
            this.requestContactInfo(contact_id)
        } else {
            this.requestAccountInfo()
        }
        this.requestProjectInfo()
    },
    methods: {

        requestContactInfo(contact_id) {
            getContactInfo(contact_id).then((res) => {
                let result = res.data.data
                this.form.account_id = result.account_id
                this.form.contact_id = contact_id
                this.form.referral_email = result.contact_email
                this.advisor_name = result.contact_enName
                this.account_nameEN = result.account.account_nameEN
            })
        },


        requestAccountInfo() {
            getAccount(this.account_id).then((res) => {
                let result = res.data.data
                this.account_nameEN = result.account_nameEN
            })
        },

        requestProjectInfo() {
            getProjectInfoByCode(this.project_code).then((res) => {
                this.project_name = res.data.project_ename
            })
        },
        submit() {
            if (!this.isAgree) {
                this.$message.warning("You must agree to the statement.");
                return;
            }
            if (false) {
                console.log('params:' + JSON.stringify(this.form))
                return
            }
            this.$refs.form.validate((val) => {
                if (val) {
                    createApplicationByProjectAndAccount(this.form).then((res) => {
                        this.$router.replace({
                            path: '/form/success',
                            query: {
                                id: res.data._id.$id,
                                project: 'registration'
                            }
                        })
                    });
                }
            });
        },
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.appform {
    font-family: GravityNormal;
    width: 100vw;
    height: 100vh;
    background-color: #FF8E3B;

    .content {
        overflow-y: auto;
        width: 1000px;
        height: 100vh;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 30px 40px 20px;
        background: #fff;
        position: relative;

        .title1 {
            margin-top: 30px;
            font-family: GravityBold;
            color: #FF8E3B;
            font-size: 22px;
            margin-bottom: 50px;
            align-items: center;
            width: 100%;
            font-weight: bold;
            text-align: center;
        }

        .row {
            display: flex;
            justify-content: space-between;

            .item {
                width: 420px;

                .label {
                    font-size: 16px;
                    font-family: GravityNormal;
                    font-weight: normal;
                    color: #666666;
                }

                .input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 18px;

                    font-family: GravityNormal;
                    font-weight: normal;
                    color: #333333;

                    img {
                        margin-right: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .submit {
        .submitBtn {
            font-family: GravityBold;
            width: 280px;
            height: 50px;
            background: #FF8E3B;
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            margin: 0 auto;
            margin-top: 20px;
            cursor: pointer;
        }

        margin-bottom: 50px;
    }
}
</style>
<style lang="scss">
.appform {
    .el-input__inner {
        border-radius: 6px;
        border-color: #FF8E3B;
        font-family: GravityNormal;
        // border: 2px solid #717171;
        // font-size: 18px;
    }

    .el-form-item__label {
        font-family: GravityNormal;
    }
}

.successConfirm {
    width: 470px;

    .el-message-box__status.el-icon-success {
        top: 11% !important;
    }
}
</style>